import { mapActions, mapGetters } from 'vuex'

import { showVueToast, convertLinkToFileName, scrollToTheTarget } from '@/utils'
import dayjs from 'dayjs'
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
export default {
  name: 'dashboard',
  components: {
    ContentCard: () => import(/* webpackChunkName: "content-card" */ '@/components/student/ContentCard'),
    Accordion: () => import(/* webpackChunkName: "accordion" */ '@/components/accordion/Accordion'),
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron'),
    Subtract: () => import(/* webpackChunkName: "subtract" */ '@/components/icons/Subtract'),
    Email: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Email'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField'),
    LinkIcon: () => import(/* webpackChunkName: "link" */ '@/components/icons/LinkIcon'),
    Attach: () => import(/* webpackChunkName: "attach" */ '@/components/icons/Attach'),
    Close: () => import(/* webpackChunkName: "close" */ '@/components/icons/Close'),
    Document: () => import(/* webpackChunkName: "document" */ '@/components/icons/Document'),
    Flag: () => import(/* webpackChunkName: "flag" */ '@/components/icons/Flag'),
    Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/pagination/Pagination'),
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem')
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  data: () => ({
    classTitle: '',
    batchClass: '',
    updatedContent: [],
    isInstructorVisible: true,
    isResourcesVisible: true,
    isAdministrationVisible: true,
    isRecordingModalVisible: false,
    isGradingModalVisible: false,
    isMessageReplyModalVisible: false,
    buttonDisable: false,
    chevronUp: 'transform: rotate(270deg); transition: transform 0.2s ease;',
    chevronDown: 'transform: rotate(90deg); transition: transform 0.2s ease;',
    newName: null,
    fileDocument: null,
    maxFileDocument: 5000000,
    currentPage: 1,
    totalDataSchedule: 0,
    totalDataMessage: 0,
    instructors: [
      {
        name: '',
        email: '',
        photo: ''
      }
    ],
    activeCard: '',
    activeGradingOrder: '',
    activeModalContent: 'show',
    indexSelected: null,
    gradingModalParams: {
      rightSide: [
        {
          label: 'Nilai',
          value: 'Belum dinilai'
        },
        {
          label: 'Catatan Instruktur',
          value: '-'
        }
      ],
      leftSide: [
        {
          label: 'Status Pengumpulan',
          value: 'Belum Mengumpulkan'
        },
        {
          label: 'Tenggat Waktu',
          value: '-'
        }
      ]
    },

    kurikulum: '',
    handbook: '',
    studentLmsClassId: '',
    recording: [],
    breadcrumb: [],
    gradingPayload: {
      link: '',
      file: '',
      fileUrl: '',
      type: '',
      scheduleId: '',
      assignmentId: ''
    },
    messageBoard: [],
    /// NEW
    submissionData: {
      scheduleId: '',
      scheduleIndex: '',
      seqNo: '',
      submissionId: '',
      submissionStatus: ''
    },
    survey: {
      title: '',
      description: '',
      link: '',
      startDate: '',
      endDate: ''
    },
    messageCreatorName: '',
    messageContentId: '',
    messageContentIndex: '',
    messageCreatorPhoto: '',
    messageContent: '',
    createdDateMessage: '',
    replyMessageContent: '',
    replyMessageUpdate: {}
  }),

  computed: {
    ...mapGetters('common', ['isMobile', 'loading']),
    ...mapGetters('student', ['lmsAnchor']),

    updatedData() {
      return this.replyMessageUpdate
    },
    totalData() {
      return this.activeCard === 'MESSAGE' ? this.totalDataMessage : this.totalDataSchedule
    },
    pageSize() {
      return this.isMobile ? 5 : 10
    },
    pageSizeMessage() {
      return this.activeCard === 'MESSAGE' ? this.pageSize : 5
    },
    userId() {
      return localStorage.getItem('student_id')
    },
    classId() {
      return this.$route.params.id
    },
    messageBoardId() {
      return this.$route.params.messageTopicId
    },
    today() {
      return dayjs().format('YYYY-MM-DD')
    },
    showSurvey() {
      return this.isShowSurvey(this.today, this.survey.startDate, this.survey.endDate)
    },
    computedBreadcrumb() {
      const bucket = []
      let counter = 0
      const bucketLength = this.breadcrumb.length * 2
      for (let i = 0; i < bucketLength; i++) {
        if (this.isEven(i)) {
          bucket[i] = this.breadcrumb[counter++]
        } else {
          bucket[i] = '>'
        }
      }
      bucket.pop()
      return bucket
    },
    checkGradingStatus() {
      if (this.gradingModalParams.leftSide[0].value === 'Belum Mengumpulkan') return false
      return true
    }
  },
  created() {
    this.messageBoardId ? this.getMessageDetail() : this.getDashboardContent()
  },
  watch: {
    activeCard(value) {
      if (value !== 'NEXT') {
        this.batchClass = this.breadcrumb[3]
      } else {
        this.batchClass = ''
      }

      if (this.messageBoardId && value === 'MESSAGE') {
        this.batchClass = 'Message Board'
        this.classTitle = this.breadcrumb[3]
      }
    },
    showSurvey(value) {
      if (value) {
        this.showClassSurvey()
      }
    },
    lmsAnchor(anchor) {
      scrollToTheTarget(`${anchor}`, -75)
    }
  },
  methods: {
    ...mapActions('student', [
      'showClassSurvey',
      'toAnchor',
      'uploadFile',
      'createAttendance',
      'getScheduleDashboard',
      'getClassById',
      'doTheTask',
      'doTheExam',
      'updateTheTask',
      'updateTheExam',
      'getMessageList',
      'replyMessage'
    ]),
    ...mapActions('prakerja', ['getUserProfile', 'updateDocumentStatus', 'uploadTugasNonWebinar']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    getStudentLmsClassId(array) {
      this.getUserProfile().then((res) => {
        array.forEach((element) => {
          if (element.student.email === res.email) {
            this.studentLmsClassId = element.id
          }
        })
      })
    },
    showDetail(index, messageTopicId) {
      if (this.activeCard === 'MESSAGE') return this.getMessagesData('DETAIL', index, messageTopicId)
      this.updatedContent[index].active = !this.updatedContent[index].active
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },

    generateLink(file) {
      return convertLinkToFileName(file, true)
    },
    toogleInstructorCard() {
      this.isInstructorVisible = !this.isInstructorVisible
    },
    toogleResourcesCard() {
      this.isResourcesVisible = !this.isResourcesVisible
    },
    toogleAdministrationCard() {
      this.isAdministrationVisible = !this.isAdministrationVisible
    },
    showRecordingModal() {
      this.isRecordingModalVisible = true
    },
    hideRecordingModal() {
      this.remove()
      this.isRecordingModalVisible = false
    },
    toogleReplyMessageModal(name, photo, date, message, messageTopicId, index) {
      if (messageTopicId) {
        this.createdDateMessage = date
        this.messageCreatorName = name
        this.messageCreatorPhoto = photo
        this.messageContent = message
        this.messageContentId = messageTopicId
        this.messageContentIndex = index
      }
      this.isMessageReplyModalVisible = !this.isMessageReplyModalVisible
    },
    toMessagePage(messageTopicId) {
      this.$router.push({ path: `/dashboard/${this.classId}/message/${messageTopicId}` })
    },

    onPageChange(page) {
      const dummyArray = new Array(10)
      if (this.activeCard === 'MESSAGE') {
        dummyArray.fill({ startTime: null, active: false, order: null, lmsMessages: [{ user: { photo: '' } }] })
      } else {
        dummyArray.fill({ startTime: null, active: false, order: null })
      }
      this.updatedContent = dummyArray
      this.currentPage = page
      if (this.activeCard === 'MESSAGE') {
        this.getMessagesData('FULL')
      } else {
        this.getScheduleContent(this.activeCard)
      }
      this.showLoading()
      this.scrollToTop()
    },
    showGradingModal(schedule, index) {
      this.indexSelected = index
      this.activeGradingOrder = schedule.seqNo
      this.gradingPayload.type = schedule.scheduleType
      this.gradingPayload.scheduleId = schedule.id
      this.gradingModalParams.leftSide[0].value = this.haveStudentsCollected(schedule)
      this.gradingModalParams.leftSide[1].value = dayjs(schedule.tanggalSubmission).format('DD MMMM YYYY HH:mm')
      this.isGradingModalVisible = !this.isGradingModalVisible
    },
    switchModalContent() {
      if (this.gradingPayload.link === '-') {
        this.gradingPayload.link = ''
      }
      this.activeModalContent = 'upload'
    },
    closeGradingModal() {
      this.isGradingModalVisible = !this.isGradingModalVisible
      this.activeModalContent = 'show'
      this.gradingPayload = {
        link: '',
        file: '',
        fileUrl: ''
      }
    },
    deleteFile() {
      this.gradingPayload.file = '-'
      this.gradingPayload.fileUrl = '-'
    },
    selectFile(evt) {
      this.showLoading()
      const selectedFiles = evt.target.files[0]
      if (!evt.target.files[0].name) {
        return
      }
      const nameFile = evt.target.files[0].name
      const data = new FormData()
      data.append('file', selectedFiles)
      data.append('name', nameFile)
      this.uploadFile({ data }).then(
        (response) => {
          this.gradingPayload.file = convertLinkToFileName(response.url, true)
          this.gradingPayload.fileUrl = response.url
          this.hideLoading()
          showVueToast('File berhasil diupload!', 'success', 1000)
        },
        () => {
          this.hideLoading()
          showVueToast('File gagal diupload!', 'error', 1000)
        }
      )
    },
    onChange() {
      this.showLoading()
      const file = this.$refs.file.files[0]
      if (file.type !== 'application/pdf' && file.type !== 'application/x-zip-compressed' && file.type !== 'application/octet-stream') {
        showVueToast('Please select a ZIP, RAR, or PDF file!', 'error', 3000)
      } else if (file.size > this.maxFileDocument) {
        showVueToast('File is larger than 5 MB!', 'error', 3000)
      } else {
        this.newName = file.name
        this.fileDocument = file
      }
      setTimeout(() => this.hideLoading(), 3500)
    },
    remove() {
      this.newName = null
      this.fileDocument = null
    },
    uploadDocument() {
      this.showLoading()
      const filePayload = new FormData()
      filePayload.append('file', this.fileDocument)
      filePayload.append('filename', this.newName)
      this.uploadTugasNonWebinar({
        studentLmsClassId: this.studentLmsClassId,
        activityItemId: this.recording[0].activityItems[1].id,
        payloads: filePayload
      }).then(
        () => {
          this.hideRecordingModal()
          this.messageBoardId ? this.getMessageDetail() : this.getDashboardContent()
          showVueToast('Upload document successfully!', 'success', 3000)
        },
        () => showVueToast('Failed request, please try again later!', 'error', 3000)
      )
      setTimeout(() => this.hideLoading(), 3500)
    },
    isEven(value) {
      if (value % 2 === 0) return true
      return false
    },
    toPage(state) {
      switch (state) {
        case 0:
          this.$router.push({ name: 'Home' })
          break
        case 2:
          this.$router.push({ name: 'Profile' })
          break
        case 4:
          if (this.messageBoardId) {
            return this.$router.push({ path: `/dashboard/${this.classId}` })
          }
          this.breadcrumb = []
          this.breadcrumb = ['Beranda', 'Kelas Saya', this.classTitle]
          this.currentPage = 1
          this.switchScheduleType('NEXT')
          break
        default:
          break
      }
    },

    haveStudentsCollected(schedule) {
      let status = ''
      let content = []
      if (schedule.scheduleType === 'TUGAS') {
        content = schedule.lmsUploadTugases.filter((item) => item.student.id === this.userId)
      } else {
        content = schedule.lmsClassUjianses.filter((item) => item.student.id === this.userId)
      }

      if (content.length === 0) {
        status = 'Belum Mengumpulkan'
        this.gradingPayload.link = '-'
        this.gradingPayload.file = '-'
      } else {
        status = 'Sudah Mengumpulkan'
        this.doesTheFileExist(content, schedule.scheduleType)
      }
      return status
    },
    doesTheFileExist(task, type) {
      this.gradingPayload.assignmentId = task[0].id
      if (type === 'TUGAS') {
        task[0].linkTugas ? (this.gradingPayload.link = task[0].linkTugas) : (this.gradingPayload.link = '-')
        task[0].tugasStudent ? (this.gradingPayload.file = convertLinkToFileName(task[0].tugasStudent, false)) : (this.gradingPayload.file = '-')
        this.gradingPayload.fileUrl = task[0].tugasStudent
        return
      }
      task[0].linkUjian ? (this.gradingPayload.link = task[0].linkUjian) : (this.gradingPayload.link = '-')
      task[0].ujian ? (this.gradingPayload.file = convertLinkToFileName(task[0].ujian, false)) : (this.gradingPayload.file = '-')
      this.gradingPayload.fileUrl = task[0].ujian
    },

    switchScheduleType(typeList) {
      this.updatedContent = []
      const dummyArray = new Array(10).fill({ startTime: null, active: false, order: null })
      this.updatedContent = dummyArray
      this.showLoading()
      this.getScheduleContent(typeList)
    },

    switchMainCard(type, breadcrumbItem, method) {
      this.scrollToTop()
      this.activeCard = type
      this.fillBreadcrumb(breadcrumbItem, method)
      this.currentPage = 1
      if (type !== 'MESSAGE') {
        return this.switchScheduleType(type)
      }
      const dummyArray = new Array(10).fill({ startTime: null, active: false, order: null, lmsMessages: [{ user: { photo: '' } }] })
      this.updatedContent = dummyArray
      this.showLoading()
      this.getMessagesData('FULL')
    },

    sendMessage() {
      this.replyMessage({
        messageTopicId: this.messageContentId,
        payloads: {
          isiPesan: this.replyMessageContent
        }
      }).then(
        (res) => {
          this.replyMessageContent = ''
          this.replyMessageUpdate = {
            createdDate: res.createdDate,
            user: {
              name: res.fullName,
              photo: localStorage.getItem('photo')
            },
            isiPesan: res.isiPesan,
            indexSelected: this.messageContentIndex
          }
          this.getMessageDetail()
          this.toogleReplyMessageModal()
        },
        () => {}
      )
    },

    getMessagesData(status, index, messageTopicId) {
      const params = {
        page: this.currentPage - 1,
        size: this.pageSizeMessage,
        sort_by: 'createdDate',
        direction: 'desc'
      }

      if (status === 'DETAIL' || status === 'SELECTED') {
        params.messageTopic = messageTopicId
      }
      this.getMessageList({ classId: this.classId, params }).then(
        (response) => {
          switch (status) {
            case 'FULL':
              this.updatedContent = []
              this.totalDataMessage = response.totalRows
              response.data.map((msg) => {
                this.updatedContent.push({ ...msg, active: false, order: msg.lmsMessages[0].judulPesan, mainMessage: msg.lmsMessages[0].isiPesan, messageTopicId: msg.id })
              })
              break
            case 'HALF':
              this.messageBoard = []
              response.data.map((msg) => {
                this.messageBoard.push({
                  messageTopicId: msg.id,
                  title: msg.lmsMessages[0].judulPesan || 'Judul pesan tidak ditemukan',
                  date: msg.createdDate
                })
              })
              break
            default:
              this.updatedContent[index].messageList = response.data[0].lmsMessages.splice(1, response.data[0].lmsMessages.length)
              this.updatedContent[index].active = !this.updatedContent[index].active
              break
          }
          this.hideLoading()
        },
        () => {}
      )
    },

    fillBreadcrumb(item, method) {
      if (this.breadcrumb.length > 3) {
        this.breadcrumb.pop()
      }

      if (method === 'IN') {
        this.breadcrumb.push(item)
        return
      }
      this.breadcrumb = this.breadcrumb.filter((val) => val !== item)
    },

    isObjectFilled(value) {
      if (Object.keys(value).length === 0 && value.constructor === Object) {
        return false
      }
      return true
    },

    doAttendance(lmsScheduleId, attend, statusAttendance, index) {
      if (statusAttendance === 'need_attendance') {
        const payloads = {
          alasan: '',
          attend,
          lmsScheduleId
        }
        this.createAttendance({ payloads }).then(
          () => {
            this.updatedContent[index].statusAttendanceIN = 'done_attendance'
            showVueToast('Absensi berhasil!', 'success', 3000)
          },
          () => {
            showVueToast('Absensi tidak berhasil, segera hubungi admin!', 'error', 4000)
          }
        )
      }
    },

    saveAssignment() {
      if (this.gradingPayload.file === '' && this.gradingPayload.link === '') {
        return
      }
      this.gradingPayload.type === 'TUGAS' ? this.saveTask() : this.saveExam()
    },

    saveTask() {
      const payloads = {
        linkTugas: this.gradingPayload.link,
        lmsScheduleId: this.gradingPayload.scheduleId,
        studentId: this.userId,
        tugasStudent: this.gradingPayload.fileUrl
      }
      if (this.checkGradingStatus) {
        this.updateTask(payloads)
        return
      }

      this.doTheTask({ payloads }).then(
        (response) => {
          this.gradingPayload.assignmentId = response.id
          this.gradingModalParams.leftSide[0].value = 'Sudah Mengumpulkan'
          this.updatedContent[this.indexSelected].lmsUploadTugases.push(response)
          this.hideLoading()
          this.closeGradingModal()
          showVueToast('Submit tugas berhasil!', 'success', 3000)
        },
        () => {
          this.hideLoading()
          showVueToast('Submit tugas gagal!', 'error', 3000)
        }
      )
    },

    fillOutSurvey() {
      window.open(this.survey.link, '_blank')
    },

    updateTask(payloads) {
      this.updateTheTask({ submissionId: this.gradingPayload.assignmentId, payloads }).then(
        (response) => {
          this.hideLoading()
          this.updatedContent[this.indexSelected].lmsUploadTugases = this.updatedContent[this.indexSelected].lmsUploadTugases.filter((item) => item.student.id !== this.userId)
          this.updatedContent[this.indexSelected].lmsUploadTugases.push(response)
          this.closeGradingModal()
          showVueToast('Update tugas berhasil!', 'success', 3000)
        },
        () => {
          this.hideLoading()
          showVueToast('Update tugas gagal!', 'error', 3000)
        }
      )
    },

    saveExam() {
      const payloads = {
        linkUjian: this.gradingPayload.link,
        lmsScheduleId: this.gradingPayload.scheduleId,
        studentId: this.userId,
        ujian: this.gradingPayload.fileUrl
      }
      if (this.checkGradingStatus) {
        this.updateExam(payloads)
        return
      }
      this.doTheExam({ payloads }).then(
        (response) => {
          this.gradingPayload.assignmentId = response.id
          this.gradingModalParams.leftSide[0].value = 'Sudah Mengumpulkan'
          this.updatedContent[this.indexSelected].lmsClassUjianses.push(response)
          this.hideLoading()
          this.closeGradingModal()
          showVueToast('Submit tugas berhasil!', 'success', 3000)
        },
        () => {
          this.hideLoading()
          showVueToast('Submit tugas gagal!', 'error', 3000)
        }
      )
    },

    updateExam(payloads) {
      this.updateTheTask({ submissionId: this.gradingPayload.assignmentId, payloads }).then(
        (response) => {
          this.updatedContent[this.indexSelected].lmsClassUjianses = this.updatedContent[this.indexSelected].lmsClassUjianses.filter((item) => item.student.id !== this.userId)
          this.updatedContent[this.indexSelected].lmsClassUjianses.push(response)
          this.hideLoading()
          this.closeGradingModal()
          showVueToast('Update exam berhasil!', 'success', 3000)
        },
        () => {
          this.hideLoading()
          showVueToast('Update exam gagal!', 'error', 3000)
        }
      )
    },

    // goToMsgList() {
    //   this.$router.push({
    //     path: `/dashboard/all-message/${this.classId}`
    //   })
    // },
    // goToChatPage(idmsg) {
    //   this.$router.push({
    //     path: `/dashboard/${this.classId}/message-board/${idmsg}`
    //   })
    // },

    async getMessageDetail() {
      this.scrollToTop()
      this.activeCard = 'MESSAGE'
      const dummyArray = new Array(1).fill({ startTime: null, active: false, order: null, lmsMessages: [{ user: { photo: '' } }] })
      this.updatedContent = dummyArray
      this.showLoading()
      await this.getClassById({ classId: this.classId }).then(
        (response) => {
          this.distributePayload(response)
        },
        () => {
          this.hideLoading()
        }
      )

      const params = {
        page: 0,
        size: 1,
        sort_by: 'createdDate',
        direction: 'desc',
        messageTopic: this.messageBoardId
      }

      await this.getMessageList({ classId: this.classId, params }).then(
        (response) => {
          this.updatedContent = []
          response.data.map((msg) => {
            this.updatedContent.push({
              ...msg,
              active: true,
              order: msg.lmsMessages[0].judulPesan,
              mainMessage: msg.lmsMessages[0].isiPesan,
              messageTopicId: msg.id,
              messageList: msg.lmsMessages.splice(1, msg.lmsMessages.length),
              disableChevron: true
            })
          })
        },
        () => {}
      )

      await this.hideLoading()
    },

    isShowSurvey(today, startDate, endDate) {
      if (dayjs(today).isSameOrAfter(startDate) && dayjs(today).isSameOrBefore(endDate)) {
        return true
      }
      return false
    },

    async getDashboardContent() {
      this.activeCard = 'NEXT'
      const dummyArray = new Array(10).fill({ startTime: null, active: false, order: null })
      this.updatedContent = dummyArray
      this.messageBoard = new Array(5).fill({ title: null, date: null })
      this.showLoading()
      await this.getClassById({ classId: this.classId }).then(
        (response) => {
          this.distributePayload(response)
        },
        () => {
          this.hideLoading()
        }
      )
      await this.getScheduleContent('NEXT')
      this.getMessagesData('HALF')
    },

    getScheduleContent(list) {
      this.activeCard = list
      const params = {
        list: list,
        page: this.currentPage - 1,
        query: '',
        size: this.pageSize,
        sortBy: 'DATE',
        sortDirection: 'ASC'
      }
      this.getScheduleDashboard({ classId: this.classId, params }).then(
        (response) => {
          this.totalDataSchedule = response.totalRows
          this.updatedContent = []
          response.data.map((sch, index) => {
            this.updatedContent.push({
              ...sch,
              index,
              submissionId: '',
              submissionLink: '',
              submissionFile: '',
              active: false,
              order: `Pertemuan ${sch.seqNo}`,
              lmsClassScheduleItemVideo: sch.lmsClassScheduleItemVideo.map((video) => {
                return {
                  ...video,
                  active: false
                }
              })
            })
          })
          this.hideLoading()
        },
        () => {
          this.hideLoading()
        }
      )
    },
    viewFile() {
      window.open(this.recording[0].activityItems[1].link)
    },
    downloadFile() {
      this.showLoading()
      this.updateDocumentStatus({
        studentLmsClassId: this.studentLmsClassId,
        activityItemId: this.recording[0].activityItems[0].id,
        payloads: {
          actionType: 'READ'
        }
      }).then(
        () => window.open(this.recording[0].activityItems[0].link),
        () => showVueToast('Failed request, please try again later!', 'error', 3000)
      )
      setTimeout(() => this.hideLoading(), 3500)
    },
    showVideoDetail(scheduleIndex, videoIndex) {
      this.updatedContent[scheduleIndex].lmsClassScheduleItemVideo[videoIndex].active = !this.updatedContent[scheduleIndex].lmsClassScheduleItemVideo[videoIndex].active
    },

    distributePayload(res) {
      this.classTitle = res.name
      this.breadcrumb = ['Beranda', 'Kelas Saya', res.name]
      if (this.messageBoardId) this.breadcrumb.push('Message Board')
      // this.batchClass = 'Batch III – 2021'
      this.kurikulum = res.kurikulum
      this.handbook = res.handBook || ''
      this.recording = res.activityStatementLetter
      this.getStudentLmsClassId(res.studentLmsClasses)
      this.instructors = []
      if (res.lmsClassExperts) {
        res.lmsClassExperts.forEach((expert) => this.instructors.push(expert.expert))
      }

      if (res.survey) {
        if (this.isObjectFilled(res.survey)) {
          this.survey.title = res.survey.title
          this.survey.description = res.survey.description
          this.survey.link = res.survey.link
          this.survey.startDate = res.survey.startDate
          this.survey.endDate = res.survey.endDate
        }
      }
    }
  }
}
